export const AOCATCommonAbilityOneProgram = () => import('./common/aoc-common-ability-one-program');
export const AOCATCommonButtonHomePage = () => import('./common/aoc-common-button-home-page');
export const AOCATCommonButtonPreviousPage = () => import('./common/aoc-common-button-previous-page');
export const AOCATCommonContactUs = () => import('./common/aoc-common-contact-us');
export const AOCATCommonError500 = () => import('./common/aoc-common-error-500');
export const AOCATCommonError404 = () => import('./common/aoc-common-error-404');
export const AOCATCommonImageSignUp = () => import('./common/aoc-common-image-sign-up');
export const AOCATCommonNewsMarketing = () => import('./common/aoc-common-news-and-marketing');
export const AOCATCommonOffersSignUp = () => import('./common/aoc-common-offers-sign-up');
export const AOCATCommonOffersSignUpContainer = () => import('./common/aoc-common-offers-sign-up-container');
export const AOCATCommonProductRecommendationsCarousel = () =>
  import('./common/aoc-common-product-recommendations-carousel');
export const AOCATCommonSearchBoxDesktop = () => import('./common/aoc-common-search-box-desktop');
export const AOCATCommonSearchBoxMobile = () => import('./common/aoc-common-search-box-mobile');
export const AOCATCommonShowMoreProductsButton = () => import('./common/aoc-common-show-more-products-button');
export const AOCATCommonTextWithIcon = () => import('./common/aoc-common-text-with-icon');
export const AOCATCommonTextWithVideo = () => import('./common/aoc-common-text-with-video');

export const AOCATFooterContact = () => import('./footer/aoc-footer-contact');
export const AOCATFooterCopyright = () => import('./footer/aoc-footer-copyright');
export const AOCATFooterOffersSignUp = () => import('./footer/aoc-footer-offers-sign-up');
export const AOCATFooterQuickLinks = () => import('./footer/aoc-footer-quick-links');

export const AOCATHeaderCollectionNavigation = () => import('./header/aoc-header-collection-navigation');
export const AOCATHeaderCollectionNavigationDesktop = () => import('./header/aoc-header-collection-navigation-desktop');
export const AOCATHeaderMenuMobile = () => import('./header/aoc-header-menu-mobile');
export const AOCATHeaderMiniCartDesktop = () => import('./header/aoc-header-mini-cart/desktop');
export const AOCATHeaderMiniCartMobile = () => import('./header/aoc-header-mini-cart/mobile');

export const AOCATProductListingBadgeNew = () => import('./product-listing/aoc-product-listing-badge-new');
export const AOCATProductListingDynamicProductList = () =>
  import('./product-listing/aoc-product-listing-dynamic-product-list');
export const AOCATProductListingFacetedNavigation = () =>
  import('./product-listing/aoc-product-listing-faceted-navigation');
export const AOCATProductListingFacetedNavigationMobile = () =>
  import('./product-listing/aoc-product-listing-faceted-navigation/mobile');
export const AOCATProductListingFacetedNavMobileContainer = () =>
  import('./product-listing/aoc-product-listing-faceted-navigation-mobile-container');
export const AOCATProductListingProductBrand = () => import('./product-listing/aoc-product-listing-product-brand');
export const AOCATProductListingProductCategoryName = () =>
  import('./product-listing/aoc-product-listing-product-category-name');
export const AOCATProductListingQuickView = () => import('./product-listing/aoc-product-listing-quick-view');
export const AOCATProductListingProductResultName = () =>
  import('./product-listing/aoc-product-listing-product-result-name');
export const AOCATProductListingSortResults = () => import('./product-listing/aoc-product-listing-sort-results');
export const AOCATProductListingCollectionList = () => import('./product-listing/aoc-product-listing-collection-list');
export const AOCATProductListingFacetedCollection = () =>
  import('./product-listing/aoc-product-listing-faceted-collection');
export const AOCATProductListingFacetedCollectionMobile = () =>
  import('./product-listing/aoc-product-listing-faceted-collection/mobile');
export const AOCATProductListingDynamicProductListingContainer = () =>
  import('./product-listing/aoc-product-listing-dynamic-product-listing-container');

export const AOCATProductAddToCartButton = () => import('./product/aoc-product-add-to-cart-button');
export const AOCATProductBadgeNew = () => import('./product/aoc-product-badge-new');
export const AOCATProductBreadcrumbs = () => import('./product/aoc-product-breadcrumbs');
export const AOCATProductBrand = () => import('./product/aoc-product-brand');
export const AOCATProductDetailsContainer = () => import('./product/aoc-product-details-container');
export const AOCATProductDocumentation = () => import('./product/aoc-product-documentation');
export const AOCATProductImageViewer = () => import('./product/aoc-product-image-viewer');
export const AOCATProductItemNumber = () => import('./product/aoc-product-item-number');
export const AOCATProductLongDescription = () => import('./product/aoc-product-long-description');
export const AOCATProductName = () => import('./product/aoc-product-name');
export const AOCATProductQuickView = () => import('./product/aoc-product-quick-view');
export const AOCATProductShortDescription = () => import('./product/aoc-product-short-description');
export const AOCATProductSpecifications = () => import('./product/aoc-product-specifications');
export const AOCATProductVariantOptions = () => import('./product/aoc-product-variant-options');

export const AOCATWishlistContainer = () => import('./wishlist/aoc-wishlist-container');
export const AOCATWishlistItemDetails = () => import('./wishlist/aoc-wishlist-item-details');
export const AOCATCategorySearchTermsSummary = () => import('./category/aoc-category-search-terms-summary');
