export * from '@oracle-cx-commerce/react-widgets';

// TRX Widgets =====================================

export const TRXAccountTypeSelectorContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistrationContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistration = () => import('./TRX/profile/trx-accounts-contacts-registration');
export const TRXCartContainer = () => import('./TRX/cart/trx-cart-container');
export const TRXCartCouponContainer = () => import('./TRX/cart/trx-cart-coupon-container');
export const TRXCartItemDetails = () => import('./TRX/cart/trx-cart-item-details');
export const TRXCartOrderSummary = () => import('./TRX/cart/trx-cart-order-summary');
export const TRXCartPromotionCodeEntry = () => import('./TRX/cart/trx-cart-promotion-code-entry');
export const TRXCartPromotionsDisplay = () => import('./TRX/cart/trx-cart-promotions-display');
export const TRXCartResumeContainer = () => import('./TRX/cart/trx-cart-resume-container');
export const TRXCategoryContentContainer = () => import('./TRX/product-listing/trx-category-content-container');
export const TRXCategoryLongDescription = () => import('./TRX/product-listing/trx-category-long-description');
export const TRXCategorySearchTermsSummary = () => import('./TRX/category/trx-category-search-terms-summary');
export const TRXCheckoutBackToPreviousButton = () => import('./TRX/checkout/trx-checkout-back-to-previous-button');
export const TRXCheckoutButton = () => import('./TRX/checkout/trx-checkout-button');
export const TRXCheckoutContinueToPaymentButton = () =>
  import('./TRX/checkout/trx-checkout-continue-to-payment-button');
export const TRXCheckoutContinueToReviewOrderButton = () =>
  import('./TRX/checkout/trx-checkout-continue-to-review-order-button');
export const TRXCheckoutCreditCard = () => import('./TRX/checkout/trx-checkout-credit-card');
export const TRXCheckoutCustomerEmail = () => import('./TRX/checkout/trx-checkout-customer-email');
export const TRXCheckoutNavigationContainer = () => import('./TRX/checkout/trx-checkout-navigation-container');
export const TRXCheckoutOrderConfirmation = () => import('./TRX/checkout/trx-checkout-order-confirmation');
export const TRXCheckoutOrderConfirmationContainer = () =>
  import('./TRX/checkout/trx-checkout-order-confirmation-container');
export const TRXCheckoutPaymentMethodsContainer = () => import('./TRX/checkout/trx-checkout-payment-methods-container');
export const TRXCheckoutPlaceOrderButton = () => import('./TRX/checkout/trx-checkout-place-order-button');
export const TRXCheckoutProgressTracker = () => import('./TRX/checkout/trx-checkout-progress-tracker');
export const TRXCheckoutRegistration = () => import('./TRX/checkout/trx-checkout-registration');
export const TRXCheckoutReviewOrderContainer = () => import('./TRX/checkout/trx-checkout-review-order-container');
export const TRXCheckoutShippingContainer = () => import('./TRX/checkout/trx-checkout-shipping-container');
export const TRXCheckoutShippingInformation = () => import('./TRX/checkout/trx-checkout-shipping-information');
export const TRXCheckoutSingleShippingDetails = () => import('./TRX/checkout/trx-checkout-single-shipping-details');
export const TRXCheckoutLogin = () => import('./TRX/checkout/trx-checkout-login');
export const TRXCheckoutReviewOrderPaymentInformation = () =>
  import('./TRX/checkout/trx-checkout-review-order-payment-information');
export const TRXCheckoutPaymentsContainer = () => import('./TRX/checkout/trx-checkout-payments-container');
export const TRXCheckoutPayInStore = () => import('./TRX/checkout/trx-checkout-pay-in-store');
export const TRXCheckoutPix = () => import('./TRX/checkout/trx-checkout-pix');
export const TRXCheckoutGiftCard = () => import('./TRX/checkout/trx-checkout-gift-card');
export const TRXCommonAlignComponents = () => import('./TRX/common/trx-common-align-components');
export const TRXCommonContainer = () => import('./TRX/common/trx-common-container');
export const TRXCommonCookieControl = () => import('./TRX/common/trx-common-cookie-control');
export const TRXCommonDynamicBreadcrumbs = () => import('./TRX/common/trx-common-dynamic-breadcrumbs');
export const TRXCommonErro500 = () => import('./TRX/common/trx-common-erro-500');
export const TRXCommonError404 = () => import('./TRX/common/trx-common-error-404');
export const TRXCommonImage = () => import('./TRX/common/trx-common-image');
export const TRXCommonImageCarousel = () => import('./TRX/common/trx-common-image-carousel');
export const TRXCommonProductRecommendationsCarousel = () =>
  import('./TRX/common/trx-common-product-recommendations-carousel');
export const TRXCommonLogo = () => import('./TRX/common/trx-common-logo');
export const TRXCommonSearchBoxDesktop = () => import('./TRX/common/trx-common-search-box-desktop');
export const TRXCommonSearchBoxMobile = () => import('./TRX/common/trx-common-search-box-mobile');
export const TRXCommonShowMoreProductsButton = () => import('./TRX/common/trx-common-show-more-products-button');
export const TRXCommonNotifications = () => import('./TRX/common/trx-common-notifications');
export const TRXContinueShoppingButton = () => import('./TRX/common/trx-continue-shopping-button');
export const TRXFooter = () => import('./TRX/footer/trx-footer');
export const TRXFooterBottom = () => import('./TRX/footer/trx-footer-bottom');
export const TRXFooterContact = () => import('./TRX/footer/trx-footer-contact');
export const TRXFooterContainerLinks = () => import('./TRX/footer/trx-footer-container-links');
export const TRXFooterLinksList = () => import('./TRX/footer/trx-footer-links-list');
export const TRXFooterPayments = () => import('./TRX/footer/trx-footer-payments');
export const TRXFooterSeparator = () => import('./TRX/footer/trx-footer-separator');
export const TRXHeaderCollectionNavigation = () => import('./TRX/header/trx-header-collection-navigation');
export const TRXHeaderCollectionNavigationDesktop = () =>
  import('./TRX/header/trx-header-collection-navigation-desktop');
export const TRXHeaderAccountPicker = () => import('./TRX/header/trx-header-account-picker');
export const TRXHeaderContainerDesktop = () => import('./TRX/header/trx-header-container/desktop');
export const TRXHeaderContainerMobile = () => import('./TRX/header/trx-header-container/mobile');
export const TRXHeaderIconsContainer = () => import('./TRX/header/trx-header-icons-container');
export const TRXHeaderMenuMobile = () => import('./TRX/header/trx-header-menu-mobile');
export const TRXHeaderMiniCartDesktop = () => import('./TRX/header/trx-header-mini-cart/desktop');
export const TRXHeaderMiniCartMobile = () => import('./TRX/header/trx-header-mini-cart/mobile');
export const TRXHeaderTopBar = () => import('./TRX/header/trx-header-top-bar');
export const TRXHeaderUserProfileLinksDesktop = () => import('./TRX/header/trx-header-user-profile-links/desktop');
export const TRXHeaderUserProfileLinksMobile = () => import('./TRX/header/trx-header-user-profile-links/mobile');
export const TRXLogin = () => import('./TRX/login/trx-login');
export const TRXLoginContainer = () => import('./TRX/login/trx-login-container');
export const TRXLoginFacebook = () => import('./TRX/login/trx-login-facebook');
export const TRXLoginGoogle = () => import('./TRX/login/trx-login-google');
export const TRXCommonNavigationBreadcrumbs = () => import('./TRX/common/trx-common-navigation-breadcrumbs');
export const TRXProductAddToCartButton = () => import('./TRX/product/trx-product-add-to-cart-button');
export const TRXProductAddToPurchaselist = () => import('./TRX/product/trx-product-add-to-purchase-list');
export const TRXProductBreadcrumbs = () => import('./TRX/product/trx-product-breadcrumbs');
export const TRXProductInventoryStatus = () => import('./TRX/product/trx-product-inventory-status');
export const TRXProductShortDescription = () => import('./TRX/product/trx-product-short-description');
export const TRXProductListingCategoryImageCarousel = () =>
  import('./TRX/product-listing/trx-product-listing-category-image-carousel');
export const TRXProductListingDynamicProductList = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-list');
export const TRXProductListingDynamicProductListingContainer = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-listing-container');
export const TRXProductListingFacetedNavigation = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation');
export const TRXProductListingProductListingSummaryInformation = () =>
  import('./TRX/product-listing/trx-product-listing-product-listing-summary-information');
export const TRXProductListingProductResultColorSwatches = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-color-swatches');
export const TRXProductListingProductResultImage = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image');
export const TRXProductListingProductResultImageQuickView = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image-quick-view');
export const TRXProductListingProductResultName = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-name');
export const TRXProductListingSortResults = () => import('./TRX/product-listing/trx-product-listing-sort-results');
export const TRXProductName = () => import('./TRX/product/trx-product-name');
export const TRXProductPrice = () => import('./TRX/product/trx-product-price');
export const TRXProductProductDetailsContainer = () => import('./TRX/product/trx-product-product-details-container');
export const TRXProductProductImageViewer = () => import('./TRX/product/trx-product-product-image-viewer');
export const TRXProductProductLongDescription = () => import('./TRX/product/trx-product-product-long-description');
export const TRXProductProductSocialSharing = () => import('./TRX/product/trx-product-product-social-sharing');
export const TRXExtendedCartItemDetails = () => import('./TRX/cart/trx-extended-cart-item-details');
export const TRXProductDeliveryOptions = () => import('./TRX/product/trx-product-delivery-options');
export const TRXProfileAddToPurchaseList = () => import('./TRX/profile/trx-profile-add-to-purchase-list');
export const TRXProductProductConfigure = () => import('./TRX/product/trx-product-product-configure');
export const TRXProductProductVariantOptions = () => import('./TRX/product/trx-product-product-variant-options');
export const TRXProductQuantity = () => import('./TRX/product/trx-product-quantity');
export const TRXProductRecommendationsCarousel = () => import('./TRX/product/trx-product-recommendations-carousel');
export const TRXProductResultPrice = () => import('./TRX/product-listing/trx-product-result-price');
export const TRXProductShippingEstimate = () => import('./TRX/product/trx-product-shipping-estimate');
export const TRXProductListingFacetedNavigationMobile = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation/mobile');
export const TRXProductListingFacetedNavigationMobileContainer = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation-mobile-container');
export const TRXProfileAccountAddressBook = () => import('./TRX/profile/trx-profile-account-address-book');
export const TRXProfileAddressBook = () => import('./TRX/profile/trx-profile-address-book');
export const TRXProfileAddressBookSummary = () => import('./TRX/profile/trx-profile-address-book-summary');
export const TRXProfileAddressForm = () => import('./TRX/profile/trx-profile-address-form');
export const TRXProfileContainer = () => import('./TRX/profile/trx-profile-container');
export const TRXProfileContainerSection = () => import('./TRX/profile/trx-profile-container-section');
export const TRXProfileDetailsSummary = () => import('./TRX/profile/trx-profile-details-summary');
export const TRXProfileEmailMarketingPreferencesSummary = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-summary');
export const TRXProfileNavigationBreadCrumbs = () => import('./TRX/profile/trx-profile-navigation-bread-crumbs');
export const TRXProfilePurchaseLists = () => import('./TRX/profile/trx-profile-purchase-lists');
export const TRXProfilePurchaseListDetailsContainer = () =>
  import('./TRX/profile/trx-profile-purchase-list-details-container');
export const TRXProfileRecentOrders = () => import('./TRX/profile/trx-profile-recent-orders');
export const TRXProfileResetPassword = () => import('./TRX/profile/trx-profile-reset-password');
export const TRXProfileSavedCardSummary = () => import('./TRX/profile/trx-profile-saved-card-summary');
export const TRXProfileShippingInformation = () => import('./TRX/profile/trx-profile-shipping-information');
export const TRXProfileUpdatePassword = () => import('./TRX/profile/trx-profile-update-password');
export const TRXProfileUpdateExpiredPassword = () => import('./TRX/profile/trx-profile-update-expired-password');
export const TRXProfileSavedCards = () => import('./TRX/profile/trx-profile-saved-cards');
export const TRXProfileAddCreditCard = () => import('./TRX/profile/trx-profile-add-credit-card');
export const TRXProfileDetails = () => import('./TRX/profile/trx-profile-details');
export const TRXProfileOrderHistory = () => import('./TRX/profile/trx-profile-order-history');
export const TRXProfileCreatePurchaseList = () => import('./TRX/profile/trx-profile-create-purchase-list');
export const TRXProfilePurchaseListsSummary = () => import('./TRX/profile/trx-profile-purchase-lists-summary');
export const TRXProfilePurchaseListSummaryInformation = () =>
  import('./TRX/profile/trx-profile-purchase-list-summary-information');
export const TRXProfilePurchaseListActionButtons = () =>
  import('./TRX/profile/trx-profile-purchase-list-action-buttons');
export const TRXProfilePurchaseListInformation = () => import('./TRX/profile/trx-profile-purchase-list-information');
export const TRXProfileWelcome = () => import('./TRX/profile/trx-profile-welcome');
export const TRXProfileEmailMarketingPreferences = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences');
export const TRXProfileEmailMarketingPreferencesContainer = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-container');
export const TRXProfileAccountDetails = () => import('./TRX/profile/trx-profile-account-details');
export const TRXProfileOrderDetailsContainer = () => import('./TRX/profile/trx-profile-order-details-container');
export const TRXProfileOrderInformation = () => import('./TRX/profile/trx-profile-order-information');
export const TRXProfileReturnsSummaryContainer = () => import('./TRX/profile/trx-profile-returns-summary-container');
export const TRXProfilePaymentInformation = () => import('./TRX/profile/trx-profile-payment-information');
export const TRXProfileOrderSummary = () => import('./TRX/profile/trx-profile-order-summary');
export const TRXProfileOrderActions = () => import('./TRX/profile/trx-profile-order-actions');
export const TRXProfileReturnRequestId = () => import('./TRX/profile/trx-profile-return-request-id');
export const TRXProfileReturnDetailsLink = () => import('./TRX/profile/trx-profile-return-details-link');
export const TRXProfileReturnRequestItems = () => import('./TRX/profile/trx-profile-return-request-items');
export const TRXProfileRegistration = () => import('./TRX/profile/trx-profile-registration');
export const TRXProfileRegistrationContainer = () => import('./TRX/profile/trx-profile-registration-container');
export const TRXProductListingProductCategoryName = () =>
  import('./TRX/product-listing/trx-product-listing-product-category-name');
export const TRXProfileAddressBookContainer = () => import('./TRX/profile/trx-profile-address-book-container');
export const TRXProfileOrderHistoryBreadcrumb = () => import('./TRX/profile/trx-profile-order-history-breadcrumb');
export const TRXProfileContactRegistrationRequests = () =>
  import('./TRX/profile/trx-profile-contact-registration-requests');
export const HerbalifeWebContent = () => import('./TRX/poc-herbalife/herbalife-web-content');
export const JostensDynamicSlider = () => import('./TRX/poc-jostens/jostens-dynamic-slider');
export const TRXCategoryImages = () => import('./TRX/category/trx-category-images');
export const TRXCommonNewsletter = () => import('./TRX/common/trx-common-newsletter');
export const TRXDynamicContent = () => import('./TRX/category/trx-dynamic-content');
export const TRXProductAddOnItems = () => import('./TRX/product/trx-product-add-on-items');
export const TRXProductQuickView = () => import('./TRX/product/trx-product-quick-view');
export const TRXProductNotifyMe = () => import('./TRX/product/trx-product-notify-me');
export const TRXCheckoutGenericPayment = () => import('./TRX/checkout/trx-checkout-generic-payment');
export const TRXProductDigitalVariants = () => import('./TRX/product/trx-product-digital-variants');
export const TRXProductDigitalShopperInputs = () => import('./TRX/product/trx-product-digital-shopper-inputs');
export const TRXCheckoutSingleShippingDetailsDigitalProducts = () =>
  import('./TRX/checkout/trx-checkout-single-shipping-details-digital-products');
export const TRXProductDigitalTermsAndConditions = () =>
  import('./TRX/product/trx-product-digital-terms-and-conditions');
export const TRXProductDigitalAddToCartButton = () => import('./TRX/product/trx-product-digital-add-to-cart-button');
export const TRXProductDigitalName = () => import('./TRX/product/trx-product-digital-name');
export const TRXProductListingQuickView = () => import('./TRX/product/trx-product-listing-quick-view');
export const TRXCartClosenessQualifier = () => import('./TRX/cart/trx-cart-closeness-qualifier');
export const TRXCartDigitalProductsEditModal = () => import('./TRX/cart/trx-cart-digital-products-edit-modal');
export const TRXCheckoutMultiShippingWithBopisDetails = () =>
  import('./TRX/checkout/trx-checkout-multi-shipping-with-bopis-details');
export const TRXCheckoutMultiShippingDetails = () => import('./TRX/checkout/trx-checkout-multi-shipping-details');
export const TRXCommonCategory = () => import('./TRX/common/trx-common-category');

// AOC
export * from './AOC';

// BSC
export * from './BSC';

//NYSPSP
export * from './NYSPSP';
