export * from '@oracle-cx-commerce/endpoints';
export const _getAddress = () => import('./TRX/getAddress');
export const _getHerbalife = () => import('./TRX/getHerbalife');
export const _getJostensDynamicImages = () => import('./TRX/getJostensDynamicImages');
export const _sendContactUsEmail = () => import('./AOC/sendContactUsEmail');

// BSC
export const _getBSCAddress = () => import('./BSC/getBSCAddress');

// NYSPSP

export * from '@oracle-cx-commerce/endpoints';
export * from '@oracle-cx-commerce/wishlist-endpoints';
export const _registerPublicUser = () => import('./NYSPSP/registerPublicUser');
export const _registerPrivateUser = () => import('./NYSPSP/registerPrivateUser');
export const _sendTaxExemptEmail = () => import('./NYSPSP/sendTaxExemptEmail');
export const _listOrganizations = () => import('./NYSPSP/listOrganizations');
export const NIBValidateAddressExperian = () => import('./NYSPSP/NIBValidateAddressExperian');
export const NIBFinalizeAddressExperian = () => import('./NYSPSP/NIBFinalizeAddressExperian');
export const NIBSaveAddress = () => import('./NYSPSP/NIBSaveAddress');
export const NIBAddressSiteUseIdCheck = () => import('./NYSPSP/NIBAddressSiteUseIdCheck');
export const _getAllOrganizationAddresses = () => import('./NYSPSP/getAllOrganizationAddresses');
export const _listQuotes = () => import('./NYSPSP/listQuotes');

export const getBackendOrders = () => import('./NYSPSP/getBackendOrders');
export const getBackendOrderDetails = () => import('./NYSPSP/getBackendOrderDetails');
export const NIBSendEmail = () => import('./NYSPSP/NIBSendEmail');

export const getUserCreditCards = () => import('./NYSPSP/getUserCreditCards');
export const NIBSaveCreditCard = () => import('./NYSPSP/NIBSaveCreditCard');
export const getInvoices = () => import('./NYSPSP/getInvoices');
export const getInvoicePdf = () => import('./NYSPSP/getInvoicePdf');
export const deleteUserCreditCard = () => import('./NYSPSP/deleteUserCreditCard');
export const updateUserCreditCard = () => import('./NYSPSP/updateUserCreditCard');
export const NIBValidateEmail = () => import('./NYSPSP/NIBValidateEmail');
export const ebsStockStatus = () => import('./NYSPSP/ebsStockStatus');
